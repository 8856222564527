@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

.instagram-post {
  .wrapper {
    max-width: 490px;
    margin: 0 auto;
  }
  .post{
    width: 100%;
    height: auto;
    background: #fff;
    width: 95%;
    margin: auto;
    border-radius: 10px;
    box-shadow: 0 0.1rem 0.2rem var(--box-shadow);
  }

  .info{
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .info .username{
    width: auto;
    font-weight: bold;
    color: #000;
    font-size: 14px;
    margin-left: 10px;
  }

  .info .options{
    height: 10px;
    cursor: pointer;
  }

  .info .user{
    display: flex;
    align-items: center;
  }

  .info .profile-pic {
    height: 40px;
    width: 40px;
    border: 1px solid gray;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: start;
    overflow: hidden;
    padding-top: 5px;
    box-sizing: border-box;
    background-color: var(--character-color);
  }

  .info .profile-pic img{
    border: none;
    width: 100%;
  }

  .post-image{
    max-width: 100%;
    height: 300px;
    object-fit: cover;
  }

  .post-content{
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    color: black;
    text-align: left;
    font-family: 'roboto', sans-serif;
  }

  .likes{
    font-weight: bold;
  }

  .description{
    margin: 10px 0;
    font-size: 14px;
    line-height: 20px;
  }

  .description span{
    font-weight: bold;
    margin-right: 10px;
  }

  .post-time{
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
  }

  .comment-wrapper .icon{
    height: 30px;
  }

  .comment-box{
    width: 80%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 14px;
  }

  .comment-btn,
  .action-btn{
    width: 70px;
    height: 100%;
    background: none;
    border: none;
    outline: none;
    text-transform: capitalize;
    font-size: 16px;
    color: rgb(0, 162, 255);
    opacity: 0.5;
  }

  .reaction-wrapper{
    width: 100%;
    height: 50px;
    display: flex;
    margin-top: -20px;
    align-items: center;
  }

  .reaction-wrapper .icon{
    height: 25px;
    margin: 0 20px 0 0;
  }

  .reaction-wrapper .icon.save{
    margin-left: auto;
    margin-right: 0;
  }

}

@media screen and (max-width:768px){
  .instagram-post {


  }
}
