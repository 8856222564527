:root {
  --white: #fff;
  --background: #f0f2f5;
  --hover-shadow: #f2f2f2;
  --hover-shadow-2: #e4e6e9;
  --border: #ccc;
  --box-shadow: rgba(0, 0, 0, 0.2);
  --reactions-links: #65676b;
  --black1: #1c1e21;
  --black2: #050505;
  --green: #2f9a48;
}

.facebook-post {
  background: var(--background);
  font-size: 1.3rem;
  font-family: "Segoe UI", Helvetica, Arial, Helvetica, sans-serif;
  border-radius: 1rem;
  zoom: 0.8;
  max-width: 490px;
  margin: 0 auto;
  a {
    text-decoration: none;
    color: inherit;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 490px;
  }

  /***************
   * POST HEADER *
   ***************/

  .post {
    background: var(--white);
    width: 100%;
    max-width: 50rem;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.2rem var(--box-shadow);
  }

  .post__header {
    display: flex;
    justify-content: space-between;
    padding: 1.2rem 1.6rem 0 1.6rem;
  }

  /* Header left */
  .header__left {
    flex: 1;
    text-align: left;
  }

  .author__name:hover,
  .post__date:hover {
    text-decoration: underline;
  }

  .author__verified,
  .post__privacy,
  .options__icon {
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    background-image: url(../images/facebook/icons-list-1.png);
    background-size: 7.1rem 43.2rem;
    background-repeat: no-repeat;
    background-position: -1.7rem -38.6rem;
  }

  .author__name {
    margin-right: 0.2rem;
  }

  .post__author-pic {
    float: left;
    width: 4rem;
    height: 4rem;
    margin-right: 0.8rem;
    border-radius: 50%;
    box-shadow: 0 0.1rem 0.2rem var(--box-shadow);
    transition: filter 0.2s ease;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;

    padding-top: 5px;
    box-sizing: border-box;
    background-color: var(--character-color);

    img {
      width: 100%;
    }
  }

  .post__author-pic:hover {
    filter: brightness(0.95);
  }

  .post__author {
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--black2);
  }

  .post__date {
    display: block;
    float: left;
    font-size: 1rem;
    color: var(--black1);
  }

  /* Dot between post author and post date */
  .post__date-privacy-separator {
    position: relative;
    bottom: 0.1rem;
  }

  .post__privacy {
    background-image: url(../images/facebook/icons-list-2.png);
    background-size: 2.6rem 89.4rem;
    background-position: 0 -78.2rem;
    opacity: 0.6;
  }

  /* Header right */
  .post__options {
    padding: 1rem;
    border-radius: 50%;
    line-height: 50%;
    transition: background-color 0.2s ease;
    cursor: pointer;
  }

  .post__options:hover {
    background: var(--hover-shadow);
  }

  .options__icon {
    height: 2rem;
    width: 2rem;
    position: relative;
    background-size: 7.4rem 43rem;
    background-position: -2.2rem -24rem;
    opacity: 0.6;
  }

  /****************
   * POST CONTENT *
   ****************/

  .content__paragraph {
    font-size: 1.2rem;
    padding: 0.8rem 1.6rem 0.8rem 1.6rem;
    color: black;
    text-align: left;
    margin: 0;
  }

  .content__image {
    max-width: 100%;
    height: 300px;
    object-fit: cover;
    display: block;
    margin: 0 auto;
  }

  .content__image:hover {
    cursor: pointer;
  }

  /***************
   * POST FOOTER *
   ***************/

  /* REACTIONS */
  .footer__reactions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.8rem 1.6rem;
    color: var(--reactions-links);
    font-size: 1rem;
  }

  .reactions__emojis {
    display: flex;
    align-items: center;
  }

  .emojis__like,
  .emojis__haha,
  .emojis__love {
    width: 1.5rem;
    height: 1.5rem;
    position: relative;
    cursor: pointer;
  }

  .emojis__like {
    z-index: 2;
  }

  .emojis__haha {
    z-index: 1;
    right: -0.2rem;
  }

  .emojis__love {
    right: -0.4rem;
  }

  .emojis__count {
    margin-left: 0.6rem;
  }

  .comment-shares__shares {
    margin-left: 0.3rem;
  }

  .emojis__count:hover,
  .comment-shares__comments:hover,
  .comment-shares__shares:hover {
    text-decoration: underline;
  }

  /* BUTTONS (LIKE, COMMENT, SHARE) */
  .footer__buttons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 1.2rem;
    border-top: 1px solid var(--border);
  }

  .buttons__like,
  .buttons__comment,
  .buttons__share {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 3.5rem;
    margin: 0.2rem 0;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 0.5rem;
    color: var(--reactions-links);
    cursor: pointer;
    transition: background-color 0.2s ease;
  }

  .buttons__like:hover,
  .buttons__comment:hover,
  .buttons__share:hover {
    background: var(--hover-shadow);
  }

  .like__icon,
  .comment__icon,
  .share__icon {
    width: 1.8rem;
    height: 1.8rem;
    display: inline-block;
    background-image: url("../images/facebook/icons-list-3.png");
    background-size: 2.6rem 77.4rem;
    background-repeat: no-repeat;
    opacity: 0.6;
    margin-right: 0.5rem;
  }

  .like__icon {
    background-position: 0 -18rem;
  }

  .comment__icon {
    background-position: 0 -14rem;
  }

  .share__icon {
    background-position: 0 -20rem;
  }

  /* POST COMMENTS */
  .footer__comments {
    padding: 0.8rem 1.2rem;
    font-size: 1rem;
    font-weight: 600;
    text-align: right;
    color: var(--reactions-links);
  }

  /* Comments filter */
  .comments__filter {
    display: inline-block;
    margin-bottom: 0.8rem;
    cursor: pointer;
  }

  .filter__icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    background-image: url("../images/facebook/icons-list-4.png");
    background-size: 7.4rem 43.4rem;
    background-repeat: no-repeat;
    background-position: -5.4rem -36.4rem;
    opacity: 0.6;
    vertical-align: middle;
  }

  /* Write a comment.. box */
  .comments__box {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  .box__profile {
    margin-left: 1rem;
    position: relative;
    width: 3.2rem;
    height: 3.2rem;
    margin-left: 0.8rem;
    border-radius: 50%;
    box-shadow: 0 0.1rem 0.2rem var(--box-shadow);
    transition: filter 0.2s ease;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 2.5rem;
    }
  }

  .box__profile::after {
    content: "";
    display: block;
    position: absolute;
    width: 0.7rem;
    height: 0.7rem;
    bottom: 0.3rem;
    right: 2.3rem;
    background: var(--green);
    border: 0.3rem solid var(--white);
    border-radius: 50%;
  }

  .box__profile:hover {
    cursor: pointer;
  }

  .profile__pic {
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
  }

  .box__bar {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .bar__input {
    width: 100%;
    background: var(--background);
    border: none;
    padding: 1.2rem;
    font-size: 1.2rem;
    color: rgba(5, 5, 5, 0.8);
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    outline: none;
    flex: 1;
  }

  .bar__input::placeholder {
    opacity: 0.8;
  }

  .bar__input:focus::-webkit-input-placeholder {
    opacity: 0.6;
  }

  .bar__emojis {
    display: flex;
    align-items: center;
    height: 3.76rem;
    background: var(--background);
    padding-right: 1.2rem;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
  }

  .emojis__insert,
  .emojis__attach,
  .emojis__gif,
  .emojis__sticker {
    border-radius: 50%;
    padding: 0.5rem;
    line-height: 0.5rem;
    margin-left: 0.3rem;
    cursor: pointer;
  }

  .emojis__insert:hover,
  .emojis__attach:hover,
  .emojis__gif:hover,
  .emojis__sticker:hover {
    background: var(--hover-shadow-2);
  }

  .insert__emoji,
  .attach__emoji,
  .gif__emoji,
  .sticker__emoji {
    width: 1.6rem;
    height: 1.6rem;
    display: inline-block;
    background-image: url("../images/facebook/icons-list-3.png");
    background-size: 2.6rem 77.4rem;
    background-repeat: no-repeat;
    opacity: 0.6;
  }

  .insert__emoji {
    background-position: 0 -32.8rem;
  }

  .attach__emoji {
    background-position: 0 -27.4rem;
  }

  .gif__emoji {
    background-position: 0 -34.6rem;
  }

  .sticker__emoji {
    background-position: 0 -40rem;
  }

  /* Friends comments */
  .comments__friend-comment {
    display: flex;
    text-align: start;
  }

  .friend-comment__pic {
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    margin-right: 1rem;
    cursor: pointer;
    transition: filter 0.2s ease;
  }

  .friend-comment__pic:hover {
    filter: brightness(0.95);
  }

  .friend-comment__comment {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-right: 0.5rem;
    padding: 0.8rem 1.2rem;
    background: var(--background);
    border-radius: 1.5rem;
  }

  .comment__author {
    align-self: flex-start;
    color: var(--black2);
    font-size: 1.35rem;
  }

  .comment__author:hover {
    text-decoration: underline;
  }

  .comment__content {
    font-size: 1.5rem;
    font-weight: 400;
    color: var(--black2);
  }

  .comment__reactions {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: -1.5rem;
    right: 0;
    padding: 0.2rem 0.5rem;
    background: var(--white);
    box-shadow: 0 0.1rem 0.2rem var(--box-shadow);
    border-radius: 1rem;
    cursor: pointer;
  }

  .reactions__emojis {
    width: 1.6rem;
    height: 1.6rem;
    position: relative;
  }

  .reactions__like {
    z-index: 2;
  }

  .reactions__haha {
    z-index: 1;
    left: -0.3rem;
  }

  .reactions__love {
    left: -0.6rem;
  }

  .reactions__count {
    position: relative;
    right: 0.1rem;
    font-size: 1.32rem;
    font-weight: 400;
    color: var(--reactions-links);
  }

  .friend-comment__options {
    align-self: center;
    padding: 0.8rem;
    border-radius: 50%;
    line-height: 0.8rem;
    cursor: pointer;
  }

  .friend-comment__options:hover {
    background: var(--hover-shadow);
  }

  .options__comment {
    width: 1.6rem;
    height: 1.6rem;
    background-position: 0 -36.8rem;
    background-size: 7.4rem 44.6rem;
    background-image: url("../images/facebook/icons-list-5.png");
  }

  .comment__links,
  .comment__replies {
    position: absolute;
  }

  /* Like, reply and comment date */
  .comment__links {
    bottom: -2rem;
    color: var(--reactions-links);
    font-size: 1.2rem;
    font-weight: 400;
  }

  .comment__replies {
    display: flex;
    align-items: center;
    bottom: -4.5rem;
  }

  .comment__replies:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  .links__like,
  .links__reply {
    font-weight: 700;
  }

  .links__like:hover,
  .links__reply:hover,
  .links__date:hover {
    text-decoration: underline;
  }

  .replies__emoji {
    width: 1.6rem;
    height: 1.6rem;
    background-image: url("../images/facebook/icons-list-6.png");
    background-position: -5.4rem -24.4rem;
    background-size: 7.4rem 32.2rem;
    background-repeat: no-repeat;
    display: inline-block;
    opacity: 0.6;
    margin-right: 0.5rem;
  }

  .comments__more-comments {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .more-comments__link:hover {
    text-decoration: underline;
  }

  .more-comments__count {
    font-weight: 400;
  }
}
