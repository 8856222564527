.popup-open {
  filter: blur(2px) brightness(70%);
}
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: white;
  padding: 30px 60px;
  max-width: 600px;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 200;
  text-align: right;
  color: black;
  border-radius: 11px;
  box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.25);

  &.popup-correct {
    background: #007A5E;
    border-bottom: 13px solid #29F095;
    color: white;
  }
  &.popup-wrong {
    background: white;
  }
  .close-popup {
    float: left;
    cursor: pointer;
  }
  .popup-header {
    background-color: white;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 77px;
    text-align: center;
    color: #0E151D;
    box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.25);
    border-radius: 68px;
    width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 0 auto;
    padding: 0 40px;
    box-sizing: border-box;
    svg {
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
  .popup-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
    margin: 20px 0;
  }
  .popup-badge {
    background-color: white;
    margin: 20px auto;
    text-align: center;
    width: 130px;
    height: 130px;
    border-radius: 100%;
    img {
      height: 50%;
      margin: 25% 0;
    }
  }
  .popup-content {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #007A5E;
    border: 1px solid #8A8A8A;
    padding: 25px 50px;
    margin: 20px 0;
    max-height: 300px;
    overflow: auto;
  }
  .popup-action {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    .action-btn {
      background: linear-gradient(285.02deg, #2BC0EF 3.25%, #8735DA 101.86%);
      border: none;
      height: 48px;
      width: 48px;
      border-radius: 48px;
      cursor: pointer;
      transform: rotateY(-180deg);
      -ms-transform: rotateY(-180deg);
      -webkit-transform: rotateY(-180deg);
      flex-grow: 0;
      flex-shrink: 0;
      &:hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
  }
  &.popup-redo {
    padding: 30px 50px;
    .popup-title {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 38px;
      line-height: 45px;
      text-align: center;
      color: #FFFFFF;
    }
    .popup-action {
      padding: 50px 0;
      .action-btn {
        transform: none;
        -ms-transform: none;
        -webkit-transform: none;
        width: 160px;
        height: 50px;
        box-sizing: border-box;
        border-radius: 84px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        cursor: pointer;
        &:nth-child(1) {
          border: 1px solid #29F095;
          background: #29F095;
          color: #007A5E;
        }
        &:nth-child(2) {
          border: 1px solid #29F095;
          background: #007A5E;
          color: #29F095;
        }
        &:hover {
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        }
      }
    }
  }
}
@media screen and (max-width: 768px){
  .popup {
    padding: 30px;
    .popup-header {
      padding: 20px 0;
      flex-direction: column;
      width: 100%;
      font-size: 24px;
      line-height: 34px;
      min-width: 250px;
    }
    .popup-content {
      font-size: 18px;
      line-height: 24px;
      padding: 20px;
    }
    .popup-action {
      flex-direction: column;
      text-align: center;
      font-size: 18px;
      line-height: 24px;
    }
  }
}
