.panel-container {
  direction: rtl;
}
.pagination {
  margin: 10px 0;
  text-align: center;
  .current-page {
    .MuiTouchRipple-root {
      background-color: blue;
      opacity: 0.1;
    }
  }
  .pagination-seperator {
    display: inline-block;
  }
}

.MuiTableCell-head {
  font-weight: bold !important;
}
