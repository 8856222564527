.twitter-post {
  font-family: 'Asap', sans-serif;
  font-family: 'Roboto', sans-serif;
  color: black;
  margin-bottom: 25px;
  border-radius: 10px;
  .avatar {
    border-radius: 100%;
    width: 42px;
    margin-right: 15px;
    background-color: var(--character-color);
    height: 42px;
    padding-top: 6px;
    overflow: hidden;
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 42px;
    img {
      max-width:100%;
    }
  }


  .tweet-wrap {
    max-width: 490px;
    background: #fff;
    margin: 0 auto;
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 0.1rem 0.2rem var(--box-shadow);
  }

  .tweet-header {
    display: flex;
    align-items:flex-start;
    font-size:14px;
  }
  .tweet-header-info {
    font-weight: bold;
    text-align: left;
  }
  .tweet-header-info span {
    color: #657786;
    font-weight: normal;
    margin-right: 5px;
  }
  .tweet-header-info p {
    font-weight:normal;
    margin-top: 5px;
  }
  .tweet-img-wrap {
    max-width: 300px;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }

  .tweet-info-counts {
    display: flex;
    justify-content: space-between;
    margin-left: 0;
    margin-top: 10px;
  }
  .tweet-info-counts div {
    display: flex;
  }
  .tweet-info-counts div svg {
    color:#657786;
    margin-right: 10px;
  }
}

@media screen and (max-width:768px){
  .twitter-post {
    .tweet-wrap {
      padding: 20px;
    }
    .tweet-header {
      flex-direction: column;
    }
    .tweet-header img {
      margin-bottom: 20px;
    }
    .tweet-header-info p {
      margin-bottom: 30px;
    }
    .tweet-img-wrap {
      padding-right: 0;
    }
    .tweet-info-counts {
      display: flex;
      margin-right: 0;
    }
  }
}
