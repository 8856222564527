.burger-menu {
  position: fixed;
  z-index: 20;
  right: 5%;
  top: 5%;
  svg {
    cursor: pointer;
    width: 50px;
    height: 50px;
  }
  .badge {
    display: block;
    position: absolute;
    width: 12px;
    height: 12px;
    bottom: 13px;
    left: 0;
    background: red;
    border: 3px solid white;
    border-radius: 50%;
  }
}
.overall-menu {
  width: 300px;
  height: 100%;
  z-index: 200;
  position: absolute;
  top: 0;
  right: 0;
  background-color: white;

  .close-menu {
    float: left;
    margin: 10px;
    padding: 10px;
    cursor: pointer;
  }
  .menu-inner {
    padding: 50px 10px;
    height: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-align: right;
    .menu-link {
      font-size: 20px;
      font-weight: 700;
      padding: 10px;
      color: #1a1a1a;
      text-decoration: none;
      cursor: pointer;
    }
    .seperator {
      border: 1px solid #8080805e;
      margin: 10px 0 20px;
    }
    .avatar {
      border-radius: 100px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-color: rgb(29, 161, 242);
      background-origin: content-box;
      padding: 10px;
      width: 150px;
      height: 150px;
      margin: 0 auto;
    }
    p {
      text-align: center;
      color: black;
      font-weight: bold;
      font-size: 24px;
    }
    .change-character {
      width: fit-content;
      cursor: pointer;
      margin-left: auto;
      margin-right: auto;
    }
    .badges {
      display: flex;
      flex-wrap: wrap;
      .badge {
        flex: 0 0 33.333333%;
        text-align: center;
        margin-bottom: 8px;
        &.not-achieved {
          cursor: default;
          filter: grayscale(100%) invert(1);
        }
        img {
          width: 80%;
          cursor: pointer;
          display: block;
          margin: 0 auto;
        }
        p {
          margin: 4px;
          font-size: 12px;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .burger-menu {
    top: 25px;
    svg {
      width: 25px;
      height: 25px;
    }
  }
  .overall-menu .menu-inner {
    .menu-link, p {
      font-size: 18px;
    }
    .avatar {
      width: 100px;
      height: 100px;
    }
    .badges .badge img {
      width: 70%;
    }
  }
}
