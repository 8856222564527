.row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  justify-content: center;
  .col {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-decoration: none;
    position: relative;
    @each $col in (10, 20, 25, 30, 33, 40, 50, 60, 70, 75, 80, 90, 100) {
      &.col-#{$col} {
        width: ($col) * 1%;
      }
    }
  }
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-150 {
  padding-bottom: 150px !important;
}
.app {
  position: relative;
  font-family: Roboto;
  min-height: 100vh;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  background: linear-gradient(103.63deg, #007A5E 2.77%, #071E54 97.67%);
  box-sizing: border-box;
  > .container {
    padding: 0 10% 250px;
    width: 100%;
    box-sizing: border-box;
    > .shared-post {
      margin: 40px 0;
    }
  }
  .header {
    height: 150px;
    width: 100%;
    background: linear-gradient(285.02deg, #8735DA 3.25%, #2BC0EF 101.86%);
    opacity: 0.37;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
    border-radius: 9px;
  }
  .header-text {
    font-style: normal;
    font-weight: bold;
    font-size: 45px;
    line-height: 150px;
    text-align: center;
    color: #FFFFFF;
    position: absolute;
  }
  .header-btn {
    position: absolute;
    left: 10%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    .go-back-btn {
      background: linear-gradient(285.02deg, #2BC0EF 3.25%, #8735DA 101.86%);
      border: none;
      height: 48px;
      width: 48px;
      border-radius: 48px;
      cursor: pointer;
    }
  }
  .title {
    min-height: 45px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 38px;
    line-height: 45px;
    text-align: center;
    color: #FFFFFF;
    padding: 60px 10% 20px 10%;
  }
  .content {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 37px;
    line-height: 43px;
    text-align: center;
    color: #FFFFFF;
    white-space: pre-wrap;
    padding: 20px 10% 40px 10%;
  }
  .text-before-btn {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    color: #FFFFFF;
    padding: 20px 10%;
  }
  .screen-action {
    position: absolute;
    bottom: 70px;
    box-sizing: border-box;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    &.screen-action-vertical {
      flex-direction: column;
      gap: 0;
    }
    .action-btn {
      background: linear-gradient(285.02deg, #2BC0EF 3.25%, #8735DA 101.86%);
      border: none;
      height: 48px;
      width: 48px;
      border-radius: 48px;
      cursor: pointer;
      transform: rotateY(-180deg);
      -ms-transform: rotateY(-180deg);
      -webkit-transform: rotateY(-180deg);
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
  }
  .btn {
    margin: 20px auto;
    background: linear-gradient(285.02deg, #8735DA 3.25%, #2BC0EF 101.86%);
    border-radius: 55.8042px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
    height: 52px;
    padding: 0 30px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    svg {
      margin-right: 10px;
    }
    &:hover {
      box-shadow: 0px 8.42328px 11.582px rgba(0, 0, 0, 0.25);
    }
  }

  .characters-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .character-container {
    cursor: pointer;
    margin: 0 10px 10px;
    width: 150px;
    .image-container {
      width: 120px;
      height: 100px;
      position: relative;
      overflow: hidden;
      border-radius: 50%;
      padding-top: 20px;
      border: 1px solid white;
      margin: 0 auto 10px;
      background-color: var(--character-color);
      img {
        height: 250px;
      }
    }
    .character-name {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      color: #FFFFFF;
      padding-bottom: 5px;
    }
    .character-profession {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #FFFFFF;
      padding: 0 10%;
    }
    &.no-expand {
      cursor: pointer;
      margin: 40px 0 0;
      position: absolute;
      right: 10%;
    }
    &:not(.no-expand):hover {
      .image-container {
        height: 250px;
        border-radius: 75px 75px 0 0;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: flex-end;
        padding-bottom: 10px;
        border-color: transparent;
      }
      .character-name {
        text-transform: uppercase;
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 35px;
        color: var(--character-color);
      }
      .character-profession {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        color: var(--character-color);
      }
    }
  }
  .selected-character-section {
    margin-top: 60px;
    .col-30 {
      display: flex;
      justify-content: flex-end;
      padding-top: 40px;
    }
    .col-70 {
      text-align: left;
      .character-profession {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 38px;
        line-height: 45px;
        color: var(--character-color);
        padding-bottom: 20px;
      }
      .character-bio {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 33px;
        white-space: pre-wrap;
        color: #FFFFFF;
      }
      .btn {
        margin: 40px 0;
      }
    }
  }
  .selected-character-container {
    margin: 0 50px;
    width: 200px;
    display: inline-block;
    .image-container {
      position: relative;
      overflow: hidden;
      margin: 0 auto 10px;
      background-color: var(--character-color);
      height: 380px;
      border-radius: 100px 100px 0 0;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: flex-end;
      padding-top: 30px;
      padding-bottom: 10px;
      border-color: transparent;
      img {
        //width: 90%;
        height: 90%;
      }
    }
    .character-name {
      text-transform: uppercase;
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 35px;
    }
  }

  .post-actions {
    position: absolute;
    bottom: 70px;
    box-sizing: border-box;
    //display: flex;
    //flex-direction: column;
    width: 80%;
    .action-text {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 33px;
      text-align: center;
      color: #FFFFFF;
    }
    .actions {
      display: flex;
      align-items: flex-start;
      flex: 1 1 auto;
      justify-content: space-around;
      padding: 0 30%;
      gap: 20px;
      .btn {
        width: 175px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        box-sizing: border-box;
        border-radius: 9px;
        display: block;
        background: #FFFFFF;
        margin-bottom: 0;
        &.btn-true {
          color: #107F59;
          border: 1px solid #007A5E;
          &:hover {
            background: #007A5E;
            box-shadow: 0px 9px 5px rgba(0, 0, 0, 0.05);
            color: white;
          }
        }
        &.btn-false {
          color: #C8102E;
          border: 1px solid #F31830;
          &:hover {
            background: #F31830;
            box-shadow: 0px 9px 5px rgba(0, 0, 0, 0.05);
            color: white;
          }
        }
      }
    }
    .current-post {
      text-align: left;
      padding: 10px 0;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
    }
    .progress-bar {
      height: 16px;
      width: 100%;
      background: #29F095;
      .current-progress {
        height: 16px;
        background-color: #007A5E !important;
      }
    }
  }

  .awards-section {
    background-color: white;
    display: flex;
    flex-direction: row;
    width: 80%;
    margin: 60px 10% 0;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.13);
    border-radius: 14px;
    .selected-character-container {
      .image-container {
        width: 200px;
      }
    }
    .awards {
      flex-grow: 1;
      text-align: left;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 35px;
      color: #525252;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px;
      .awards-content {
        font-size: 37px;
        line-height: 43px;
        color: #201E1E;
        white-space: pre-wrap;
      }
      .badges {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .badge {
          width: 60px;
          height: 60px;
          background: #FFFFFF;
          border: 1.31837px solid #FFFFFF;
          box-sizing: border-box;
          box-shadow: 0px 2.96633px 4.61429px rgba(0, 0, 0, 0.18);
          border-radius: 100%;
          margin: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 60%;
          }
          &.not-achieved {
            background: #f6f6f6;
            box-shadow: none;
            cursor: default;
            filter: grayscale(80%);
          }
        }
      }
    }
  }
  .final-section {
    .content {
      margin: 70px 10%;
      padding: 80px 40px;
      border: 1px solid #29F095;
      box-sizing: border-box;
      border-radius: 24px;
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 38px;
      line-height: 45px;
      text-align: center;
      color: #FFFFFF;
    }
    .btn {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 35px;
      text-align: center;

      color: #29F095;
      background: none;
      padding: 0;
      border-radius: 0;
      &:hover {
        box-shadow: none;
      }
    }
  }
}
.badges-panel {
  width: 350px;
  position: absolute;
  right: 0;
  top: 150px;
  z-index: 5;
  background: #046750;
  border-radius: 0 0 0 45px;
  .badges {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 20px 0;

    .badge {
      width: 100px;
      height: 100px;
      background: #FFFFFF;
      border: 1.31837px solid #FFFFFF;
      box-sizing: border-box;
      box-shadow: 0px 2.96633px 4.61429px rgba(0, 0, 0, 0.18);
      border-radius: 100%;
      margin: 10px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 60%;
      }
      &.not-achieved {
        opacity: 0.2;
        box-shadow: none;
        border: none;
      }
    }
  }
}
@media screen and (max-width: 1024px){
  .app {
    .selected-character-section {
      .col-30 {
        width: 40%;
        justify-content: center;
      }
      .col-70 {
        width: 60%;
      }
    }
    .selected-character-container {
      margin: 0 30px;
    }
    .awards-section .awards, .awards-section .awards .awards-content {
      font-size: 22px;
      line-height: 26px;
    }
    .character-container.no-expand {
      top: 0;
      width: auto;
      .image-container {
        width: 80px;
        height: 80px;
        box-sizing: border-box;
        margin: 0 0 0 auto;
        padding-top: 10px;
        img {
          width: 90%;
        }
      }
      .character-name {
        display: none;
      }
    }
  }
}
@media screen and (max-width: 768px){
  .app {
    .selected-character-section {
      .col-30 {
        width: 100%;
        justify-content: center;
      }
      .col-70 {
        padding-top: 40px;
        width: 100%;
        justify-content: center;
        .btn {
          margin: 40px auto;
        }
      }
    }
    .awards-section {
      flex-direction: column;
      .selected-character-container {
        margin: 0 auto;
      }
      .awards {
        text-align: center;
        .badges {
          justify-content: center;
        }
      }
    }
    .screen-action {
      .action-btn {
        flex-grow: 0;
        flex-shrink: 0;
      }
    }
  }
}
